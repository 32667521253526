import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { BackdropLoadingComponent } from 'src/app/backdrop-loading/backdrop-loading.component';

@Component({
  selector: 'app-passenger-layout',
  templateUrl: './passenger-layout.component.html',
  styleUrls: ['./passenger-layout.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterModule, HeaderComponent, FooterComponent, BackdropLoadingComponent]
})
export class PassengerLayoutComponent {
  isMenuVisible: boolean = false;
  isLoading: boolean = false;

  onMenuToggle(isVisible: boolean): void {
    this.isMenuVisible = isVisible;
  }

  loadData() {
    this.isLoading = true;
    // Simulate data loading with a timeout
    // setTimeout(() => {
    //   this.isLoading = false;
    // }, 3000);
  }
}
