// src/app/error/error.component.ts
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core'; // นำเข้า TranslateService

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
  standalone: true,
  imports: [ TranslateModule],
})
export class ErrorComponent implements OnInit {
  errorMessage: string = 'An unexpected error occurred';

  constructor(
    private route: ActivatedRoute,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      let message = params['message']; // Create a new variable to hold the message

      if (message) {
        if (message == '%5Bobject%20Object%5D') {
          message = this.translate.instant('MRTA_PARKING_ERROR');
        }
        else if(message.includes('Http failure response'))
        {
          message = this.translate.instant('underconstruction');
        }
        this.errorMessage = decodeURIComponent(message);
      }
    });
  }
}
