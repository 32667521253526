import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-backdrop-loading',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './backdrop-loading.component.html',
  styleUrl: './backdrop-loading.component.scss'
})
export class BackdropLoadingComponent {
  @Input() isLoading: boolean = false;
}
