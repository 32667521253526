import { Component, EventEmitter, Output, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RouteParamService } from '../../views/booking/shared/route-param.service';
import { environment } from '../../../environments/environment';
import { ErrorHandlingService } from '../../services/error-handling.service';
import { ApiService } from '../../services/api.service'; // Import ApiService
import { WebSocketService } from '../../services/websocket.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule],
  providers: [WebSocketService],
})
export class HeaderComponent implements OnInit {
  @Output() menuToggle = new EventEmitter<boolean>();
  isMenuVisible: boolean = false;

  id: string | null = null;
  fullname: string | null = null;

  showLogo: boolean = false;
  showBookingHistoryTitle: boolean = false; // ตัวแปรใหม่สำหรับตรวจสอบการแสดง "ประวัติการจอง"
  showCompliantHistoryTitle: boolean = false; // ตัวแปรใหม่สำหรับตรวจสอบการแสดง "ประวัติการจอง"

  textHeading: string | null = null;
  showHeading: boolean = false;

  constructor(
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private routeParamService: RouteParamService,
    private errorHandlingService: ErrorHandlingService,
    private location: Location,
    private apiService: ApiService // Add ApiService to the constructor
  ) {
    translate.setDefaultLang('th');
  }

  toggleMenu(): void {
    this.isMenuVisible = !this.isMenuVisible;
    this.menuToggle.emit(this.isMenuVisible);
  }

  ngOnInit(): void {
    this.route.fragment.subscribe(fragment => {
      // ฟังก์ชันเมื่อ fragment เปลี่ยนแปลง
    });

    // Fetch fullname from localStorage
    this.fullname = localStorage.getItem('fullname');

    // ตรวจสอบเส้นทางปัจจุบันเพื่อกำหนดการแสดงผลของโลโก้, ปุ่ม Back หรือ "ประวัติการจอง"
    this.router.events.subscribe(() => {
      this.showLogo = this.checkShowLogo();

      this.showHeading = this.checkShowHeading();
    });
  }

  checkShowLogo(): boolean {
    const currentUrl = this.router.url;
    // ถ้า URL ปัจจุบันเป็น booking-history หรือ complaint-detail ให้ซ่อนโลโก้
    return !(currentUrl.includes('/booking-detail/') || currentUrl.includes('/complaint-detail/') || currentUrl.includes('/booking-history'));
  }

  checkShowHeading(): boolean {
    const currentUrl = this.router.url;
    let check = false;

    if (currentUrl.includes('/booking-detail')) {
      this.textHeading = this.translate.instant('booking_detail');
      check = true;
    }
    else if (currentUrl.includes('/booking-history')) {
      this.textHeading = this.translate.instant('booking_history');
      check = true;
    } else if (currentUrl.includes('/complaint-detail')) {
      this.textHeading = this.translate.instant('compliant_detail');
      check = true;
    }
    else if (currentUrl.includes('/complaint-history')) {
      this.textHeading = this.translate.instant('compliant_history');
      check = true;
    }

    return check;
  }



  gotoBackRoute(): void {
    const currentUrl = this.router.url;


    // หากเป็นเส้นทาง `complaint-detail/:id` ให้เปลี่ยนการแสดงผล
    if (currentUrl.includes('/complaint-history/')) {
      this.router.navigate(['/']);
    }
    else if (currentUrl.includes('/booking-history/'))
      this.router.navigate(['/']);
    else
      this.location.back();
  }

  switchLanguage(language: string) {
    this.translate.use(language);
  }

  // ฟังก์ชันสำหรับกลับไปหน้าหลักโดยไม่ต้อง reload หรือ refresh หน้าใหม่
  gotoHome(): void {
    console.log('isMenuVisible', this.isMenuVisible);

    if (this.isMenuVisible)
      this.toggleMenu();

    const currentUrl = this.router.url;

    // ถ้า URL ปัจจุบันไม่ใช่หน้าแรก
    if (currentUrl !== '/' && currentUrl !== '/#') {
      this.router.navigate(['/'], { skipLocationChange: true }).then(() => {
        // ไม่ต้องเรียก window.history.replaceState
        // เพียงแค่นำทางไปที่หน้าแรกโดยไม่เปลี่ยน URL
      });
    } else {
      // ถ้าอยู่ที่หน้าแรกแล้ว ไม่ต้องทำอะไร
      return;
    }

    this.changeUrl('');
  }

  changeUrl(newUrl: string) {
    this.location.go(newUrl);
  }

  gotoProfile(): void {
    this.toggleMenu();
    this.router.navigate(['/profile']);
  }

  gotoBookingHistory(): void {
    this.toggleMenu();
    this.router.navigate(['/booking-history']);
  }

  gotoCompliantHistory(): void {
    this.toggleMenu();
    this.router.navigate(['/complaint-history']);
  }
}
