import { Routes } from '@angular/router';
import { DefaultLayoutComponent } from './layout';
import { PassengerLayoutComponent } from './passenger-layout/passenger-layout.component';
import { ErrorComponent } from './error/error.component';

export const routes: Routes = [
  {
    path: '',
    component: PassengerLayoutComponent,
    children: [
      { path: 'error', component: ErrorComponent },
      {
        path: 'passenger',
        loadChildren: () => import('./views/passenger/routes').then((m) => m.routes),
        data: { title: 'Passenger' }
      },
      {
        path: 'traveling/:id',
        loadChildren: () => import('./views/booking/traveling/traveling.routes').then(m => m.travelingRoutes),
        data: { title: 'Traveling' }
      },
      {
        path: 'select-car',
        loadChildren: () => import('./views/booking/select-car/select-car.routes').then(m => m.selectCarRoutes),
        data: { title: 'MRTA EV Taxi Select Car' }
      },
      {
        path: 'booking_map',
        loadChildren: () => import('./views/booking/search-map/search-map.routes').then(m => m.searchMapRoutes),
        data: { title: 'MRTA EV Taxi Booking Map' }
      },
      {
        path: 'cancel-booking',
        loadChildren: () => import('./views/booking/cancel-booking/cancel-booking.routes').then(m => m.CancelBookingRoutes),
        data: { title: 'MRTA EV Booking Cancel Success' }
      },
      {
        path: 'compliant-booking',
        loadChildren: () => import('./views/booking/complaint/success/success.routes').then(m => m.SuccessRoutes),
        data: { title: 'Booking Complaint Success' }
      },
      {
        path: 'waiting-driver-confirm/:id',
        loadChildren: () => import('./views/booking/waiting-driver-confirm/waiting-driver-confirm.routes').then(m => m.WaitingDriverConfirmRoutes),
        data: { title: 'MRTA EV Taxi Search Driver' }
      },
      {
        path: 'evaluation/:id',
        loadChildren: () => import('./views/booking/evaluation/form/form.routes').then(m => m.EvaluationFormRoutes),
        data: { title: 'MRTA EV Evaluation' }
      },
      {
        path: 'evaluation-success',
        loadChildren: () => import('./views/booking/evaluation/success/success.routes').then(m => m.EvaluationSuccessRoutes),
        data: { title: 'MRTA EV Evaluation' }
      },
      {
        path: 'booking-history',
        loadChildren: () => import('./views/booking/history/list/list.routes').then(m => m.HistoryListRoutes),
        data: { title: 'Booking History' }
      },
      {
        path: 'booking-detail/:id',
        loadChildren: () => import('./views/booking/history/detail/detail.routes').then(m => m.HistoryDetailRoutes),
        data: { title: 'Booking Detail' }
      },
      {
        path: 'complaint-history',
        loadChildren: () => import('./views/booking/complaint/list/list.routes').then(m => m.ComplaintHistoryListRoutes),
        data: { title: 'Complaint History' }
      },
      {
        path: 'complaint-detail/:id',
        loadChildren: () => import('./views/booking/complaint/detail/detail.routes').then(m => m.ComplaintDetailRoutes),
        data: { title: 'Complaint Detail' }
      },
      {
        path: 'profile',
        loadChildren: () => import('./views/profile/detail.route').then(m => m.DetailRoutes),
        data: { title: 'Passenger Profile' }
      },
      {
        path: '',
        loadChildren: () => import('./views/booking/search_form/search_form.routes').then(m => m.searchFormRoutes),
        data: { title: 'MRTA - EV Taxi' }
      },
      {
        path: ':id',
        loadChildren: () => import('./views/booking/search_form/search_form.routes').then(m => m.searchFormRoutes),
        data: { title: 'MRTA - EV Taxi' }
      },
      {
        path: 'CustomerBooking/:id',
        loadChildren: () => import('./views/booking/search_form/search_form.routes').then(m => m.searchFormRoutes),
        data: { title: 'MRTA - EV Taxi' }
      }
    ]
  },
  {
    path: '404',
    loadComponent: () => import('./views/pages/page404/page404.component').then(m => m.Page404Component),
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    loadComponent: () => import('./views/pages/page500/page500.component').then(m => m.Page500Component),
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    loadComponent: () => import('./views/pages/login/login.component').then(m => m.LoginComponent),
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'register',
    loadComponent: () => import('./views/pages/register/register.component').then(m => m.RegisterComponent),
    data: {
      title: 'Register Page'
    }
  },

  { path: '**', redirectTo: '/' }  // Redirect to error page for undefined routes
];
