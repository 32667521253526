
  
<div class="passenger-layout">
  <app-header (menuToggle)="onMenuToggle($event)"></app-header>
  <app-backdrop-loading [isLoading]="isLoading"></app-backdrop-loading>
  <div *ngIf="!isMenuVisible" class="main-content" id="passenger-main-content">
    <router-outlet></router-outlet>
  </div>
  <app-footer></app-footer>
</div>


