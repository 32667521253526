import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PassengerLayoutComponent } from './passenger-layout.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { BackdropLoadingComponent } from 'src/app/backdrop-loading/backdrop-loading.component';
import { SocketioService } from 'src/app/services/socketio.service';

import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { provideMessaging, getMessaging } from '@angular/fire/messaging';
import { environment } from '../../environments/environment';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    PassengerLayoutComponent,  // import standalone component
    HeaderComponent,  // import standalone component
    FooterComponent,  // import standalone component
    BackdropLoadingComponent  // import standalone component
  ],
  exports: [
    PassengerLayoutComponent  // export ได้ทันทีหลังจาก import
  ],
  providers: [
    SocketioService,
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),  // ตั้งค่า Firebase
    provideMessaging(() => getMessaging())  // ตั้งค่า Messaging
  ]
})
export class PassengerLayoutModule { }
